<template>
  <div id="App" class="text-gray-600">
    <IncludeFonts v-bind="{ flows }" />
    <a-layout>
      <Sidebar v-if="user && !isIntegrationsHost" />
      <a-layout-content class="bg-gray-100">
        <TopBar v-if="!isIntegrationsHost" :page="pageHeader || { title: 'Home', icon: 'home' }" />
        <div class="main-view scroller">
          <router-view v-bind="{ reNavigating }" />
        </div>
      </a-layout-content>
    </a-layout>

    <!-- <ActionButtons
      class="fixed bottom-4 right-4 z-50"
      direction="horizontal"
      :buttons="actionButtons"
      @state="onStateUpdate"
    /> -->
    <Popup isFlush :isOpen="Boolean(openContactId)" @close="openContactId = null">
      <FullContactView :contactId="openContactId" @close="openContactId = null" />
    </Popup>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import Firebase from 'firebase/app'
import 'firebase/firestore'
import 'firebase/functions'

import '@/helpers/prototypes'
import updateIndexes from '@/helpers/algolia'
import api from '@/helpers/api'

import Moment from 'moment'

import createGroup from '@/data/createGroup'
import updateActiveGroup from '@/data/updateActiveGroup'
import getIpData from '@/helpers/locationData'
import flowsInOrder from '@/components/explorer/helpers/flowsInOrder'
import { copyToClipboard } from '@/helpers/clipboard'

// Boot Up
import IncludeFonts from './boot-up/IncludeFonts.vue'
import { postUserInit } from './boot-up/initServices'

// Components
import Sidebar from '@/components/Sidebar'
import TopBar from '@/components/TopBar'
import FullContactView from '@/components/FullContactView'
// import ActionButtons from './components/utilities/ActionButtons.vue'

export default {
  name: 'App',
  components: {
    IncludeFonts,
    Sidebar,
    TopBar,
    FullContactView,
    // ActionButtons,
  },
  provide() {
    return {
      _queueDeleteEvent: this.queueDeleteEvent,
      _isAdmin: () => this.isAdmin,
      _editingMode: () => this.$store.state.editingMode,
      _getConnections: () => (this.loadingConnections ? null : this.connections),
      _updateConnections: this.updateConnections,
      _openContact: this.openContact,
      _register: (key, fn) => {
        this.$set(this.registered, key, fn)

        const self = this
        return function() {
          if (self.registered[key] === fn) self.$set(self.registered, key, undefined)
        }
      },
      _getRegistered: key => this.registered[key],
      // _registerButton: this.registerButton,
      // _setButtonState: this.setButtonState,
    }
  },
  data() {
    return {
      reNavigating: new Date(),
      eventData: null,
      unreadContacts: [],
      electronEventsDisplay: [],
      eventDeleteQueue: [],
      connections: {},
      loadingConnections: false,
      openContactId: null,
      registered: {},
      extraButtons: [],
      buttonState: {},
    }
  },
  computed: {
    ...mapState(['pages', 'pageHeader', 'userDoc', 'groups', 'allFlows', 'users']),
    ...mapGetters([
      'userId',
      'user',
      'activeGroupId',
      'activeGroup',
      'flows',
      'isAdmin',
      'algoliaTokens',
      'groupIds',
      'groupsById',
    ]),
    isIntegrationsHost() {
      console.log(
        "window.location.hostname === 'integrations.trysavvy.com'",
        window.location.hostname === 'integrations.trysavvy.com'
      )
      return window.location.hostname === 'integrations.trysavvy.com'
    },
    routePath() {
      return this.$route.path
    },
    activeGroupName() {
      return this.activeGroup && this.activeGroup.name
    },
    actionButtons() {
      const baseButtons = [
        // {
        //   id: 'help',
        //   tooltip: 'Get Help',
        //   icon: 'question',
        //   toggle: true,
        //   toggledOnIcon: 'question-circle',
        //   buttons: [
        //     {
        //       id: 'search-command-bar',
        //       tooltip: 'Search for an answer',
        //       icon: 'search',
        //       click: () => window.CommandBar && window.CommandBar.open('', { categoryFilter: 801 }),
        //     },
        //     {
        //       id: 'docs',
        //       tooltip: 'Open the docs',
        //       icon: 'book',
        //       click: () => window.open('https://trysavvy.gitbook.io/savvy-flows/', '_blank'),
        //     },
        //     // {
        //     //   id: 'papercups',
        //     //   tooltip: 'Chat with a Savvy Expert',
        //     //   icon: 'comment',
        //     //   // toggle: true,
        //     //   click: () => window.dispatchEvent(new Event('papercups:open')),
        //     //   // toggledOnClasses: 'bg-indigo-500 text-white',
        //     //   // toggledOff: () => window.dispatchEvent(new Event('papercups:close')),
        //     // },
        //   ],
        // },
      ]

      const callback = (b, stateObj) => {
        const buttons = b.buttons
          ? b.buttons.map(bt => callback(bt, stateObj[`${b.id}-state`] || {}))
          : undefined
        return { ...b, buttons, initialOn: stateObj[b.id] || false }
      }
      return [...baseButtons, ...this.extraButtons].map(b => callback(b, this.buttonState || {}))
    },
    products() {
      return this.$store.getters.products
        .map(g => ({ ...g, id: g.id, label: g.name || g.title }))
        .filter(g => g.label)
    },
    groupIdFromQuery() {
      const query = this.$route.query || {}
      return query.groupId
    },
    contactIdFromQuery() {
      const query = this.$route.query || {}
      return query.contactId
    },
  },
  watch: {
    isIntegrationsHost: {
      handler(val) {
        if (val) {
          console.log('hi')
          this.$router.replace('/integrations')
          setTimeout(() => {
            this.$router.replace('/integrations')
          }, 1000)
          setTimeout(() => {
            this.$router.replace('/integrations')
          }, 2000)
          setTimeout(() => {
            this.$router.replace('/integrations')
          }, 3000)
        }
      },
      immediate: true,
    },
    algoliaTokens: {
      handler(ai) {
        const indexes = updateIndexes(ai)
        this.$store.commit('setAlgoliaIndices', indexes)
      },
      immediate: true,
      deep: true,
    },
    routePath(routePath, oldRoutePath) {
      if (routePath !== oldRoutePath) this.reNavigating = new Date()
    },
    users: {
      handler(v) {
        if (window.CommandBar && v && v.length > 1) {
          window.CommandBar.addContext({
            users: v.map(v => ({
              email: v.auth && v.auth.email,
              name: v.auth && (v.auth.displayName || v.auth.email),
              id: v.id,
            })),
          })
        }
      },
    },
    isAdmin: {
      handler(v, o) {
        /* Bind admin status binds, if not admin then re-do group binds to get group specific flows */
        if (v === o) return
        this.$store.dispatch('bindToAdminStatus')

        if (!v) this.$store.dispatch('bindToCurrentGroup')

        if (window.CommandBar) window.CommandBar.addContext({ isAdmin: v })
      },
      immediate: true,
    },
    groupIdFromQuery: {
      async handler(id) {
        if (id) {
          if (this.activeGroupId !== id) {
            updateActiveGroup({ value: id }, true)
          } else this.$router.replace({ ...this.$route, query: {} })
        }
      },
      immediate: true,
    },
    contactIdFromQuery: {
      async handler(id) {
        if (id) {
          const query = { ...this.$route.query }
          delete query.contactId
          this.$router.replace({ ...this.$route, query })
          this.openContact(id)
        }
      },
      immediate: true,
    },
    activeGroupId: {
      async handler(newVal, oldVal) {
        this.$store.dispatch('bindToCurrentGroup')
        if (newVal) {
          if (window.CommandBar) {
            window.CommandBar.addContext({ activeGroupId: newVal })
          }
        }

        if (oldVal && newVal && oldVal !== newVal) {
          const recentActiveGroupIds = (
            await Firebase.firestore()
              .collection('users')
              .doc(this.userId)
              .get()
          ).data().recentActiveGroupIds
          Firebase.firestore()
            .collection('users')
            .doc(this.userId)
            .update({
              recentActiveGroupIds: Array.from(new Set([newVal, ...(recentActiveGroupIds || [])])),
            })
        }
      },
      immediate: true,
    },
    '$store.state.allFlows': {
      handler(allFlows) {
        if (!window.CommandBar) return
        const flows = flowsInOrder(allFlows, this.user)
        window.CommandBar.addContext({
          flows: flows
            .filter(f => !f._archived)
            .map(f => {
              const groupId = f._meta && f._meta.groupId
              const group = this.groupsById[groupId]
              const groupName = group && (group.name || group.title)
              const label = f.title || `Untitled (${f.id})`
              return { label, id: f.id, groupId, groupName }
            }),
        })
      },
      immediate: true,
      deep: true,
    },
  },
  async mounted() {
    await postUserInit(this.user, this)
    this.addCbCommands()
  },
  methods: {
    // registerButton(button) {
    //   const exists = this.extraButtons.findIndex(b => b.id === button.id)
    //   const buttons =
    //     exists !== -1
    //       ? this.extraButtons.map((b, i) => (i === exists ? button : b))
    //       : [...this.extraButtons, button]

    //   this.extraButtons = buttons

    //   return () => {
    //     this.extraButtons = this.extraButtons.filter(b => b.id !== button.id)
    //     this.setButtonState(button.id, false)
    //   }
    // },
    // onStateUpdate(event) {
    //   this.buttonState = event
    // },
    // setButtonState(key, val, obj) {
    //   let currObj = obj || this.buttonState
    //   if (currObj.hasOwnProperty(key)) {
    //     this.$set(currObj, key, val)
    //   } else if (currObj.hasOwnProperty(`${key}-state`)) {
    //     Object.entries(currObj)
    //       .filter(([id]) => id.endsWith('-state'))
    //       .forEach(([, state]) => this.setButtonState(key, val, state))
    //   }
    //   // if (currObj === this.buttonState) this.buttonState = { ...this.buttonState }
    // },
    async setIpData() {
      const { ip } = await getIpData()
      if (ip)
        Firebase.firestore()
          .collection('groups')
          .doc(this.activeGroupId)
          .collection('data')
          .doc('seen-ip-addresses')
          .set({ ipAddresses: Firebase.firestore.FieldValue.arrayUnion(ip) }, { merge: true })
    },
    addCbCommands() {
      const self = this

      const onSearchContacts = async inputText => {
        const contacts = await this.$store.state.algoliaIndices.contacts.index
          .search(inputText, { filters: `groupId:${this.activeGroupId}`, distinct: 1 })
          .then(res => {
            return res.hits.map(hit => ({
              ...hit,
              id: hit.baseId || hit.objectID,
              label:
                hit.customName ||
                hit.name ||
                (hit.firstName && `${hit.firstName} ${hit.lastName}`) ||
                hit.email ||
                `Anonymous Visitor from ${
                  hit.city
                    ? `${hit.city}, ${hit.country_code === 'US' ? hit.region : hit.country_name}`
                    : Moment(hit.updatedAt).fromNow()
                }`,
            }))
          })

        return contacts
      }

      const openContact = result => {
        self.openContact(Object.values(result)[0].id)
      }

      // Register search function to CommandBar
      window.CommandBar.addSearch('contacts', onSearchContacts)
      window.CommandBar.addCallback('openContact', openContact)

      window.CommandBar.addCallback('toggleEditingMode', function() {
        self.$store.commit('toggleEditingMode')
      })

      window.CommandBar.addCallback('getFeedbackUrl', async args => {
        const flowId = args.flow.id
        let url = `https://flows.trysavvy.com/flow/${flowId}/feedback`
        copyToClipboard(url)
        await this.$nextTick()
        this.$message.info(`${args.flow.label} share url copied to clipboard!`)
      })

      window.CommandBar.addCallback('getShareFlowUrl', async args => {
        const urlType = args.version
        const flowId = args.flow.id
        if (!urlType) return
        let url = `https://flows.trysavvy.com/flow/${flowId}`
        switch (urlType.toLowerCase()) {
          case 'live': {
            break
          }

          case 'latest':
          default: {
            url += `?version=${urlType.toLowerCase()}`
            break
          }
        }
        copyToClipboard(url)
        await this.$nextTick()
        this.$message.info(`${args.flow.label} share url copied to clipboard!`)
      })

      window.CommandBar.addCallback('openDiahookUrl', async args => {
        const groupId = args.group.id
        if (!groupId) return
        const { data } = await api('/groups/diahook', { groupId })
        const url = data.url
        window.open(url, '_blank')
      })

      window.CommandBar.addCallback('authorizeUser', async args => {
        const userId = args.user.id
        if (!userId || !this.isAdmin) return
        const { data } = await api('/user/warrant-authorize', {
          userId,
          type: 'flow',
          id: 'general',
          role: 'editor',
        })

        if (data) {
          if (data.message) this.$message.info(data.message)
          else this.$message.info('User authorized!')
        } else this.$message.info('Could not authorize user')
      })

      window.CommandBar.addCallback('addGroup', async args => {
        const emails = [args.email1, args.email2, args.email3].filter(e => e)
        const { groupId } = await createGroup(emails, args.groupName, this.user)
        if (args.goToGroup === 'yes' && groupId)
          Firebase.firestore()
            .collection('users')
            .doc(self.$store.getters.userId)
            .update({ activeGroupId: groupId })
      })

      window.CommandBar.addCallback('renameGroup', async args => {
        if (args.group && args.group.id && args.name)
          Firebase.firestore()
            .collection('groups')
            .doc(args.group.id)
            .update({ title: args.name, name: args.name })
      })

      window.CommandBar.addCallback('renameFlow', async args => {
        if (args.flow && args.flow.id && args.name)
          Firebase.firestore()
            .collection('forms')
            .doc(args.flow.id)
            .update({ title: args.name })
      })

      window.CommandBar.addCallback('viewContext', function() {
        console.log(window.CommandBar.shareContext())
      })

      window.CommandBar.addCallback('inviteUserToOrg', args => {
        const groupId = args.org.id
        const user = args.user
        const email = user.email
        const authorizedEditor = args.authorizedEditor
        const callable = Firebase.functions().httpsCallable('createInvites')

        self.$message.info('Sending invite...')

        callable({ email, groupId, sender: this.user.auth, authorizedEditor }).then(res => {
          console.log('qwer res', res)
          self.$message.success('Invite sent!')
        })
      })

      window.CommandBar.addCallback('createInviteForManyEmails', args => {
        const email = args.email
        const groupId = args.org.id

        const callable = Firebase.functions().httpsCallable('createInvites')

        self.$message.info('Sending invite...')

        callable({ email, groupId, sender: this.user.auth }).then(res => {
          console.log('qwer res', res)
          self.$message.success('Invite sent!')
        })
      })
    },
    queueDeleteEvent(e, del) {
      if (del) {
        this.eventDeleteQueue = this.eventDeleteQueue.filter(
          ev => !(ev.selector === e.selector && ev.eventId === e.eventId)
        )
      } else this.eventDeleteQueue = [...this.eventDeleteQueue, e]
    },
    setEventResultData(events) {
      this.electronEventsDisplay = events
    },
    setEventData(d) {
      this.eventData = d
    },
    openContact(id) {
      this.openContactId = id
    },
    async updateConnections(service) {
      this.loadingConnections = true
      try {
        const { data } = await api('/integrations/status', {
          groupId: this.activeGroupId,
          service,
        })
        this.connections = { ...this.connections, ...data }
      } catch (error) {
        console.error(error)
      }
      this.loadingConnections = false
    },
  },
}
</script>

<style lang="scss">
@import '@/styles/_variables.scss';
@import '@/styles/_ant_base.scss';

#commandbar-launcher {
  display: none;
}

#App {
  @extend .variables;
  @extend .base-to-extend;

  .ant-layout {
    height: 100vh;

    .ant-layout-header {
      position: fixed;
      width: 100%;
      z-index: 20;
      background: none;
      padding: 0;
      height: unset;
      line-height: inherit;
    }
    .ant-layout-content {
      position: relative;
      overflow: hidden;
      display: flex;
      flex-direction: column;

      > div:not(.full-width) {
        // width: 90%;
        // max-width: 1280px;
        margin: 0;
      }

      > div.full-width {
        margin: 0 auto;
        width: 100%;
      }

      > .main-view {
        flex: 1;

        > .ant-page-header {
          padding: 20px;

          .ant-page-header-heading-title {
            font-size: 22px;
            color: inherit;

            .Icon {
              --fa-primary-color: var(--color-savvy);
            }
          }
          .ant-page-header-heading-tags {
            margin: 5px;
          }
        }
      }
    }
  }
}
</style>
