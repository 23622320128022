<template>
  <div class="FullContactView">
    <Entity
      collection="contacts"
      :id="contactId"
      v-model="firebaseContact"
      @loaded="loaded = true"
    />
    <ContactProfile :id="contactId" :contact="fullContact" @close="$emit('close', $event)" />
    <div class="activity-section">
      <ContactReplay
        :id="contactId"
        :sessionPageviews="playbackData && playbackData.sessionPageviews"
        :pageviewId="playbackId === true ? null : playbackId"
      />

      <ContactTimeline
        :id="contactId"
        :contact="fullContact"
        v-bind="{ playbackId, usedFallback }"
        @play-recording="data => (playbackData = data)"
      />
    </div>
  </div>
</template>

<script>
import api from '@/helpers/api'
import ContactProfile from '@/components/explorer/ContactProfile'
import ContactReplay from '@/components/explorer/ContactReplay'
import ContactTimeline from '@/components/explorer/ContactTimeline'
import { mapGetters } from 'vuex'

export default {
  components: {
    ContactProfile,
    ContactReplay,
    ContactTimeline,
  },
  props: {
    contactId: String,
    algoliaContact: Object,
  },
  data() {
    return {
      firebaseContact: null,
      playbackData: null,
      loaded: false,
      usedFallback: false,
    }
  },
  computed: {
    ...mapGetters(['activeGroupId']),
    fullContact() {
      return this.firebaseContact || this.algoliaContact
    },
    playbackId() {
      return this.playbackData && this.playbackData.playbackId
    },
    journey() {
      return this.$store.getters.activeOrMainJourney
    },
    stages() {
      return this.journey && this.journey.stages
    },
    contactNotFound() {
      return this.loaded && !this.firebaseContact
    },
  },
  watch: {
    contactNotFound: {
      async handler(v) {
        if (v) {
          const { data } = await api(`/contacts/${this.contactId}/fetch`, {
            groupId: this.activeGroupId,
          })
          if (data && data.contact) {
            this.firebaseContact = data.contact
            this.usedFallback = true
          }
        }
      },
    },
  },
}
</script>

<style lang="scss">
@import '@/styles/_variables.scss';

.FullContactView {
  border-radius: 10px;
  overflow: hidden;
  display: grid;
  grid-template-columns: 360px 1fr;

  .ContactProfile {
    padding: 40px 20px;
    background: #f5f5f5;
  }
  .activity-section {
    padding: 40px;

    .ContactTimeline > div {
      margin: 40px 0;
    }
  }
}
</style>
