<template>
  <a-layout-sider
    :style="{
      flex: `0 0 ${width}`,
      width: 'auto',
      'min-width': 'auto',
      'max-width': 'auto',
      'z-index': 210,
    }"
  >
    <HomeGroupSelection hidden v-model="availGroups" />
    <div
      class="sidebar-main bg-gray-800 border-r"
      :class="{ isCollapsed, recentlyCollapsed }"
      @mouseleave="recentlyCollapsed = false"
    >
      <div class="header">
        <a-icon type="menu" @click="isCollapsed = !isCollapsed" />

        <a-dropdown v-if="currentProject && projects.length > 1" :trigger="['click']">
          <div class="project-selector">
            {{ currentProject && currentProject.name }}
            <a-icon type="down" />
          </div>
          <a-menu slot="overlay" @click="changeProject">
            <a-menu-item v-for="{ id, name } in projects" :key="id">
              {{ name }}
            </a-menu-item>
          </a-menu>
        </a-dropdown>
        <!-- <a-tooltip v-if="!onlineStatus">
          <template slot="title">You might be offline</template>
          <a-icon type="disconnect" style="color:red;" />
        </a-tooltip> -->
      </div>
      <div class="sidebar-content scroller">
        <div
          v-for="{ id: sectionId, title, links, divider } in sections"
          :key="title || sectionId"
          class="section"
          :class="{ divider }"
        >
          <template v-if="!divider">
            <div v-if="title" class="section-heading">
              <div class="title" :style="{ opacity: isCollapsed ? 0 : 1 }">
                {{ title }}
              </div>
              <a-icon type="plus" @click="beginCreatingPage(sectionId)" />
            </div>
          </template>
          <router-link
            class="link"
            v-for="{ id: pageId, title: pageTitle, icon, route, hidden, exactOnly } in links"
            :key="pageId"
            :to="route"
            :class="{ exactOnly }"
          >
            <div class="icon-container">
              <Icon v-if="icon" :name="icon" type="fal" />
              <div v-else-if="pageTitle" class="initials-icon">
                {{ getInitials(pageTitle, 3) }}
              </div>
            </div>
            <div class="title">{{ pageTitle }} {{ hidden ? '(Hidden)' : '' }}</div>
          </router-link>
        </div>
      </div>
      <div class="footer">
        <div class="quick-upload" @click="uploadFile = true">
          <Icon name="upload" type="fal" />
          <div class="upload-text whitespace-nowrap">Quick File Upload</div>
        </div>
        <a-space :size="12">
          <AccountDropdown v-bind="{ projects }">
            <a-avatar style="cursor: pointer;" :src="user ? user.picture : null" icon="user" />
          </AccountDropdown>

          <template v-if="activeGroup">
            <a-dropdown v-if="availGroups && availGroups.length > 1" :trigger="['click']">
              <a
                class="ant-dropdown-link"
                @click="e => e.preventDefault()"
                style="white-space: nowrap;"
              >
                {{ activeGroup.name }} <a-icon type="down" />
              </a>
              <a-menu slot="overlay">
                <a-menu-item
                  v-for="group in availGroups"
                  :key="group.value || group.id"
                  @click="updateActiveGroup(group)"
                >
                  <a-icon type="user" /> {{ group.text }}
                  <template v-if="commentsPerGroup[group.value]">
                    ({{ commentsPerGroup[group.value] }})
                  </template>
                </a-menu-item>
              </a-menu>
            </a-dropdown>
            <div class="org-name text-white" v-else>{{ activeGroup.name }}</div>
          </template>
        </a-space>

        <!-- <SafeImage
        class="profile-picture"
        :src="user ? user.picture : ''"
        fallback="/images/profile.png"
      />
      <router-link to="/settings"><div class="text">Billing & Settings</div></router-link> -->
      </div>
    </div>
    <a-modal v-model="uploadFile" :footer="null" title="Upload File">
      <a-upload :fileList="fileList" name="file" :customRequest="customRequest">
        <a-button> <a-icon type="upload" /> Click to Upload </a-button>
      </a-upload>
      <template v-if="fileUrl">
        Download Url:
        <pre class="flex flex-row" @click="copyToClipboard()">
          <textarea :rows="Math.ceil(fileUrl.length / 20)" class="pointer-events-none outline-none resize-none" ref="input" type="text" :value="fileUrl" style="width: 100ch;" />
        </pre>
      </template>
    </a-modal>
  </a-layout-sider>
</template>

<script>
import Firebase from '@firebase/app'
import '@firebase/firestore'
import Reference from 'firebase-storage-lite'
import { mapState, mapGetters } from 'vuex'

import { getInitials } from '@/helpers/textStringConversions'
import { copyToClipboard } from '@/helpers/clipboard'
import flowsInOrder from './explorer/helpers/flowsInOrder'

import HomeGroupSelection from '@/components/popups/HomeGroupSelection'
import AccountDropdown from '@/components/explorer/AccountDropdown'

export default {
  components: {
    HomeGroupSelection,
    AccountDropdown,
  },
  inject: ['_getRegistered'],
  data() {
    return {
      isCollapsed: localStorage.SavvySidebarIsCollapsed
        ? JSON.parse(localStorage.SavvySidebarIsCollapsed)
        : false,
      pages: [],
      // uploadFile: false,
      fileList: [],
      fileUrl: null,
      availGroups: [],
      recentlyCollapsed: false,
      comments: [],
    }
  },
  computed: {
    ...mapState(['editingMode', 'allFlows', 'projects', 'currentProjectId']),
    ...mapGetters(['activeGroupId', 'activeGroup', 'activeGroupId', 'user', 'isAdmin', 'flows']),
    uploadFile: {
      get() {
        return this.$store.state.uploadingFile
      },
      set(v) {
        this.$store.commit('setUploading', v)
      },
    },
    projectId() {
      if (this.currentProjectId) return this.currentProjectId
      if (this.activeGroupId === 'fxBRfFf86NatwbLBLgJ2') return 'ZaMe4ttHoD0CBC0aXyxQ'
      return this.projects[0] ? this.projects[0].id : null
    },
    currentProject() {
      return this.projects.find(p => p.id === this.projectId)
    },
    width() {
      return this.isCollapsed ? '54px' : '200px'
    },
    flowsInOrder() {
      return flowsInOrder(this.flows, this.user)
    },
    fileCallback() {
      return this._getRegistered('fileUploadCallback')
    },
    sections() {
      return [
        {
          id: 'home',
          title: null,
          links: [{ id: 'home', title: 'Overview', icon: 'home' }],
        },
        this.$store.getters.userId !== 'SIYGcjRJtNgLq9yDvVItRhF45vV2' && {
          id: 'people',
          title: null,
          links: [{ id: 'people', title: 'People', icon: 'users', route: '/people' }],
        },
        {
          id: 'flows',
          title: null,
          links: [
            { id: 'flows', title: 'Flows', icon: 'map-signs', exactOnly: true },
            ...this.flowsInOrder.map(({ id, title }) => ({ id, title })).slice(0, 5),
          ],
        },
        {
          id: 'integrations',
          title: null,
          links: [
            {
              id: 'integrations',
              title: 'Integrations',
              icon: 'cogs',
              exactOnly: true,
              route: '/integrations',
            },
          ],
        },
        {
          id: 'billing',
          title: null,
          links: [
            {
              id: 'billing',
              title: 'Billing',
              icon: 'credit-card',
              exactOnly: true,
              route: '/billing',
            },
          ],
        },
      ]
        .filterExists()
        .map(s => ({
          ...s,
          links: s.links.map(l => ({
            route:
              l.route ||
              `/project/${s.id || 'pages'}${
                l.id !== s.id
                  ? `/${l.id}${
                      this.$route.path.endsWith('/edit') &&
                      this.$route.path.split('/').slice(-2)[0] !== l.id
                        ? '/edit'
                        : ''
                    }`
                  : ''
              }`,
            ...l,
          })),
        }))
    },
    commentsPerGroup() {
      const commentsByFlow = this.comments.reduce((acc, c) => {
        const flowId = c._metadata.flowId
        if (!c.archived) acc[flowId] = acc[flowId] ? acc[flowId] + 1 : 1
        return acc
      }, {})
      const flowsByGroup = this.allFlows.reduce((acc, f) => {
        if (f._meta && f._meta.groupId) {
          acc[f._meta.groupId] = Array.isArray(acc[f._meta.groupId]) ? acc[f._meta.groupId] : []
          acc[f._meta.groupId].push(f.id)
        }
        return acc
      }, {})
      return this.availGroups.reduce((acc, g) => {
        if (Array.isArray(flowsByGroup[g.value])) {
          const sum = flowsByGroup[g.value].reduce(
            (acc2, flowId) => acc2 + (commentsByFlow[flowId] || 0),
            0
          )
          if (sum) acc[g.value] = sum
        }
        return acc
      }, {})
    },
  },
  async mounted() {
    const collectionGroup = Firebase.firestore()
      .collectionGroup('comments')
      .where('resolved', '==', false)
    const serialize = doc => {
      const data = doc.data() || {}
      data._metadata = { flowId: doc.ref.path.split('/')[1] }
      return Object.defineProperty(data, 'id', { value: doc.id })
    }
    this.$bind('comments', collectionGroup, { serialize })
  },
  watch: {
    projectId: {
      handler(v, o) {
        if (v === o) return
        if (v) this.changeProject({ key: v })
      },
      immediate: true,
    },
    isCollapsed(isCollapsed, old) {
      if (isCollapsed && !old) this.recentlyCollapsed = true
      localStorage.SavvySidebarIsCollapsed = isCollapsed
    },
  },
  methods: {
    getInitials,
    changeProject({ key }) {
      this.$store.commit('setProject', key)
    },
    async updateActiveGroup(group) {
      await Firebase.firestore()
        .collection('users')
        .doc(this.$store.getters.userId)
        .update({ activeGroupId: group.value })
      this.$router.replace('/')
    },
    async customRequest(upload) {
      const { file } = upload
      this.file = { ...file, name: file.name }
      this.fileList = [{ ...file, name: file.name }]
      this.onUploadFile({ file })
    },
    async onUploadFile({ file }) {
      const matchingFlow = this.flows.find(f => f.id === this.$route.params.id)
      const id = matchingFlow ? matchingFlow.id : null
      const ref = new Reference(
        `gs://savvy-flow-assets/${id || this.projectId}/${file.name.split('.')[0]}_${(
          '_' + Math.random()
        ).slice(3)}.${file.name
          .split('.')
          .slice(1)
          .join('.')}`
      )
      const upload = ref.put(file, { cacheControl: 'public,max-age=300' })
      upload.catch(e => {
        console.error('error!', e)
        this.$message.error(`Error uploading file: ${e.message}`)
      })
      upload.finally(() => {
        ref.getDownloadURL().then(url => {
          this.fileUrl = url
          this.copyToClipboard()
          if (this.fileCallback) this.fileCallback(url)
        })
      })
    },
    async copyToClipboard() {
      const url = this.fileUrl
      await copyToClipboard(url)
      await this.$nextTick()
      if (this.$refs.input) this.$refs.input.select()
      this.$message.info(`File url copied to clipboard`)
    },
  },
}
</script>

<style scoped lang="scss">
@import '@/styles/_variables.scss';

// $sidebar_background: #261d75;

.ant-layout-sider {
  position: relative;
  transition: flex 0.3s;

  .sidebar-main {
    @include flex('column', 'a-stretch');
    // background: $sidebar_background;
    height: 100%;
    position: absolute;
    z-index: 50;
    top: 0;
    bottom: 0;
    left: 0;
    width: 200px;
    max-width: 200px;
    min-width: 200px;
    transition: margin 0.3s, width 0.3s, min-width 0.3s, max-width 0.3s;

    // &.isCollapsed:not(:hover),
    // &.recentlyCollapsed,
    &.isCollapsed {
      width: 54px;
      max-width: 54px;
      min-width: 54px;

      .project-selector {
        display: none;
      }
    }

    .link,
    .project-selector {
      @include flex('row', 'a-center');
      height: 34px;
      margin: 4px 8px;
      padding: 4px;
      border-radius: 4px;
      transition: background-color 0.1s;
      cursor: pointer;
      color: white;

      &:hover {
        background: rgba(255, 255, 255, 0.1);
      }
    }

    .header {
      @include flex('row', 'a-center');
      margin-bottom: 20px;
      padding: 9px 8px;

      > .anticon {
        font-size: 20px;
        padding: 8px;
        border-radius: 4px;
        color: white;

        &:hover {
          background: rgba(255, 255, 255, 0.1);
        }
      }
      .project-selector {
        padding: 2px 10px;
        margin: 0 4px;

        .anticon {
          margin: 0.125em 0 0 0.375em;
        }
      }
    }

    .sidebar-content {
      flex: 1;

      .hover-show {
        display: flex;
        flex-direction: row;
        align-items: center;
        opacity: 0;
        margin: 4px 8px;
        padding: 8px;
        transition: 0.2s opacity linear;

        > div {
          display: inline;
          margin-left: 16px;
        }
      }
      &:hover > .hover-show {
        opacity: 1;
        cursor: pointer;
        transition: 0.2s opacity linear;
      }

      .Icon,
      .anticon {
        font-size: 16px;
      }

      .section {
        margin-bottom: 20px;

        &:not(:hover) {
          .section-heading .anticon {
            display: none;
          }
        }
        &.divider {
          border-top: 1px solid rgba(255, 255, 255, 0.1);
          margin: -24px 16px 12px;
        }

        .section-heading {
          @include flex('row', 'j-between', 'a-center');
          margin: 16px;

          .title {
            font-size: 0.9em;
            text-transform: uppercase;
            transition: opacity 0.3s;
          }
          .Icon {
            font-size: 1em;
            cursor: pointer;

            &:hover {
              opacity: 0.8;
            }
          }
        }

        a {
          position: relative;

          &.router-link-exact-active,
          &.router-link-active:not(.exactOnly),
          &:hover {
            .icon-container .initials-icon {
              background: rgba(0, 0, 0, 0.05);
            }
          }
          &.router-link-exact-active,
          &.router-link-active:not(.exactOnly) {
            background: rgba(255, 255, 255, 0.1);
            color: $savvy;
            color: white;
          }

          .icon-container {
            flex: 0 0 30px;
            height: 100%;
            @include flex('row', 'center');

            .initials-icon {
              @include flex('row', 'center');
              // background-color: rgba(255, 255, 255, 0.8);
              border-radius: 4px;
              width: 24px;
              height: 24px;
              padding: 4px;
              box-sizing: border-box;
              font-weight: 600;
              // color: $sidebar_background;
              text-align: center;
              font-size: 0.8em;
            }
          }
          .title {
            margin-left: 4px;
            margin-bottom: 2px;
            flex: 1;
            white-space: nowrap;
            text-overflow: ellipsis;
          }
        }
      }
    }
    .footer {
      // @include flex('row', 'j-end');
      // padding: 16px;
      padding: 16px 10.5px;
      transition: padding 0.3s;

      a {
        color: white;
      }
      .quick-upload {
        border-radius: 4px;
        cursor: pointer;
        flex: 0 0 30px;
        height: 30px;
        color: white;
        padding: 8px 4px;
        margin: 0px 4px 12px 4px;
        @include flex('row');
        align-items: center;
        .upload-text {
          margin-left: 12px;
        }
        &:hover {
          background: rgba(255, 255, 255, 0.1);
        }
      }
    }

    &.isCollapsed:not(:hover) {
      a,
      div.hover-show {
        .title {
          display: none;
          position: absolute;
          left: 48px;
          top: auto;
          bottom: auto;
          white-space: nowrap;
          padding-left: 10.5px;
          padding: 6px 12px;
          border-radius: 4px;
          // background: rgba($sidebar_background, 0.8);
          z-index: 40;
        }

        &:hover {
          .title {
            display: block;
          }
        }
      }
      .footer .ant-dropdown-link,
      .upload-text,
      .org-name {
        display: none;
      }
    }

    &:not(.isCollapsed),
    &:hover {
      .sidebar-content {
        .section a {
          overflow: hidden;

          // .icon-container .initials-icon {
          //   display: none;
          // }
        }
      }
    }
  }

  @media (max-width: 600px) {
    display: none;
  }
}
</style>
