import Algolia from 'algoliasearch'
import { ALGOLIA_APP_ID, INDEX_NAMES } from '@/constants'

export default function updateIndexes(tokens) {
  const algoliaIndices = {}
  Object.entries(tokens || {}).forEach(([index, token]) => {
    const client = Algolia(ALGOLIA_APP_ID, token)
    const algoliaIndex = client.initIndex(INDEX_NAMES[index])
    algoliaIndices[index] = {
      index: algoliaIndex,
      client,
      clear: () => client.clearCache(),
    }
  })
  return algoliaIndices
}
