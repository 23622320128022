<template>
  <div class="ContactProfile">
    <div>
      <div v-if="hasClose">
        <a-button shape="round" @click="$emit('close')">Close</a-button>
      </div>
      <div class="flex flex-row justify-between pb-5 w-full">
        <div>
          <div class="font-bold text-lg mb-2">
            <a-icon type="user" />
            {{ contactDetails.name }}
          </div>
          <div class="flex flex-row items-center w-full">
            <div v-if="contactDetails.email" class="mr-6 mb-2 font-medium">
              {{ contactDetails.email }}
            </div>
            <div v-if="contactDetails.company" class="mr-6 mb-2 font-medium">
              {{ contactDetails.company }}
            </div>
            <div v-if="contactDetails.role" class="mr-6 mb-2 font-medium">
              {{ contactDetails.role }}
            </div>
          </div>
          <div class="last-seen mb-4">
            Last Seen: <span class="font-medium">{{ lastSeenDate | fromNow }}</span>
          </div>
          <a-dropdown v-if="contactDetails.email">
            <a-menu slot="overlay" @click="handleMenuClick">
              <a-menu-item key="mail">
                <a :href="`mailto:${contactDetails.email}`" target="_blank">
                  <a-icon type="mail" /> Email
                </a>
              </a-menu-item>
            </a-menu>
            <a-button type="primary"> Actions <a-icon type="down" /> </a-button>
          </a-dropdown>
        </div>
        <div class="w-2/6 flex flex-col items-center justify-center font-bold">
          <vue-ellipse-progress
            :size="75"
            color="#6454f3"
            thickness="12%"
            :progress="percent"
            legendClass="progress-bar-legend"
          >
            <span></span>
          </vue-ellipse-progress>
          <div>{{ percentDisplayText }}</div>
        </div>
      </div>
      <EntryCard v-for="response in flowResponses" :key="response.id" :card="response" />
      <EntryCard title="Contact Details" :card="{ answers: details }" />
    </div>
    <a-divider />
    <div v-if="$store.getters.isAdmin">
      <a-button @click="openContactInFirebase">
        View In Firebase
      </a-button>
    </div>
    <div class="mt-2">
      <a-popconfirm
        title="Deleting this contact can take a few minutes. Are you sure?"
        okType="danger"
        ok-text="Delete"
        cancel-text="No"
        @confirm="createDeletionRequest(id)"
      >
        <a-button icon="delete">Delete Contact</a-button>
      </a-popconfirm>
    </div>
  </div>
</template>

<script>
import Firebase from '@firebase/app'
import '@firebase/firestore'
import get from 'lodash/get'
import Vue from 'vue'
import { copyToClipboard } from '@/helpers/clipboard'
import { mapGetters } from 'vuex'
import { toCapitalCase } from '@/helpers/textStringConversions'
import VueEllipseProgress from 'vue-ellipse-progress'
import EntryCard from './EntryCard.vue'

Vue.use(VueEllipseProgress)

export default {
  name: 'ContactProfile',
  components: { EntryCard },
  props: {
    id: String,
    contact: Object,
    stages: Array,
    hasClose: Boolean,
  },
  computed: {
    ...mapGetters(['flows']),
    allFlows() {
      return this.flows.reduce((acc, f) => {
        acc[f.id] = f
        return acc
      }, {})
    },
    percent() {
      const flows = Object.values(get(this.contact, 'data.flows', {}))

      if (flows.length) {
        const percent = Math.max(
          ...flows.map(flow => (flow.highest_page_reached_index / flow.current_total_pages) * 100)
        )

        if (percent) return percent
      }

      if (!this.contact || !this.contact._computed) return 0
      const stages = this.contact._computed.currentStageIndex || 0
      return Math.round((100 * (stages + 1)) / this.stages.length)
    },
    percentDisplayText() {
      return this.percent === 100 ? 'Completed' : `${Math.round(this.percent)}%`
    },
    contactDetails() {
      const city = (this.contact && this.contact.city) || ''
      const region = (this.contact && this.contact.region) || ''
      const country = (this.contact && this.contact.country_name) || ''
      return {
        name: this.name || 'Anonymous',
        company: this.companyName,
        email: this.contact && this.contact.email,
        savvyId: this.contact && this.contact.id,
        role: this.contact && this.contact.role,
        location: [city, region, country].filter(f => f).join(', '),
        lastSeen: this.lastSeen,
        flowsTaken: this.flowResponses.map(f => f.title).join(', '),
      }
    },
    flowResponses() {
      const flows = (this.contact && this.contact.data && this.contact.data.flows) || {}
      const removeKeys = new Set([
        'createdAt',
        'updatedAt',
        'submitted_timestamps',
        '_empty_keys',
        '_non_empty_keys',
        'highest_page_reached_id',
        'highest_page_reached_index',
        'current_total_pages',
      ])
      return Object.entries(flows)
        .filter(([, d]) => Object.keys(d).length)
        .map(([id, d]) => {
          const flow = this.allFlows[id] || {}
          return {
            id,
            title: flow.title || `Untitled Flow (Id: ${id})`,
            answers: Object.entries(d)
              .filter(([k]) => !removeKeys.has(k))
              .map(([k, v]) => ({
                label: toCapitalCase(k),
                value: Array.isArray(v) ? v.join(', ') : v, // Todo - use the label from the key on the value
              }))
              .sort((a, b) => a.label.localeCompare(b.label)),
          }
        })
        .filter(o => o.answers.length)
    },
    details() {
      return [
        { label: 'Company', value: this.contactDetails.company },
        { label: 'Email', value: this.contactDetails.email },
        { label: 'Role', value: this.contactDetails.role },
        { label: 'Location', value: this.contactDetails.location },
        { label: 'Last Seen', value: this.contactDetails.lastSeen },
        { label: 'Flows Taken', value: this.contactDetails.flowsTaken },
        { label: 'IP Address', value: this.contact && this.contact.ip },
        { label: 'Savvy ID', value: this.contactDetails.savvyId },
      ]
    },
    firstSeen() {
      if (!this.contact || !this.contact.createdAt) return null
      const date = new Date(
        this.contact.createdAt.seconds
          ? this.contact.createdAt.seconds * 1000
          : this.contact.createdAt
      )
      return `${date.toDateString()} at ${date.toLocaleTimeString()}`
    },
    lastSeenDate() {
      if (!this.contact) return null
      const date =
        typeof this.contact.updatedAt === 'string'
          ? new Date(this.contact.updatedAt)
          : new Date(this.contact.updatedAt.seconds * 1000)
      return date
    },
    lastSeen() {
      if (!this.contact) return 'Never'
      return `${this.lastSeenDate.toDateString()} at ${this.lastSeenDate.toLocaleTimeString()}`
    },
    companyName() {
      const contact = this.contact || {}
      return (
        (contact.company && contact.company.name) ||
        contact.customCompanyName ||
        (typeof contact.company === 'string' && contact.company) ||
        (contact.albacrossCompany && this.contact.albacrossCompany.name)
      )
    },
    name() {
      if (!this.contact) return ''
      return (
        this.contact.customName ||
        this.contact.name ||
        `${this.contact.firstName || ''} ${this.contact.lastName || ''}`.trim() ||
        ''
      )
    },
  },
  methods: {
    async createDeletionRequest(id) {
      await Firebase.firestore()
        .doc(`storage-logs/contact-delete-requests/requests/${id}`)
        .set({ requestedAt: new Date() })

      this.$message.info(
        'Your request has been submitted. Please allow a few minutes for deletion.'
      )
      this.$store.commit('stageContactForDeletion', id)
      this.$emit('close', true)
    },
    openContactInFirebase(event) {
      const url = `https://console.firebase.google.com/u/0/project/savvy-app-live/database/firestore/data/contacts~2F${this.id}`

      if (event.metaKey) {
        copyToClipboard(url)
      } else {
        window.open(url)
      }
    },
    handleMenuClick({ key }) {
      switch (key) {
        case 'email': {
          /* Anchor tag handles this */
          return
        }

        default:
          return
      }
    },
  },
}
</script>
