<template>
  <div class="ContactReplay">
    <!-- <Button text="Play" :click="play" /> -->
    <Spinner v-if="loading" class="loading" />
    <div v-else-if="errortype" class="error">
      {{ errorMessage }}
    </div>
    <div v-else-if="noEventsFound" class="no-recording-data">
      No recording data found
    </div>
    <!-- <template v-else> -->
    <div class="video-placeholder" ref="videoPlaceholder" />
    <div class="video" ref="videoContainer" />
    <!-- </template> -->
  </div>
</template>

<script>
import Firebase from '@firebase/app'
import '@firebase/firestore'
import '@firebase/storage'
import rrwebPlayer from 'rrweb-player'
import Axios from 'axios'
import 'rrweb-player/dist/style.css'

export default {
  name: 'ContactReplay',
  props: {
    id: String,
    pageviewId: String,
    sessionPageviews: Array,
  },
  data() {
    return {
      events: [],
      noEventsFound: false,
      player: null,
      loading: false,
      errorData: null,
      errortype: null,
    }
  },
  computed: {
    eventsToWatch() {
      if (this.pageviewId) return this.events.filter(e => e.pageviewId === this.pageviewId)

      const pageviews = new Set(this.sessionPageviews || [])
      return this.sessionPageviews ? this.events.filter(e => pageviews.has(e.pageviewId)) : [] //this.events
    },
    errorMessage() {
      switch (this.errortype) {
        case 'incomplete-data':
          return 'Unfortunately, recording data for this event is incomplete / corrupted.'
        case 'no-recordings':
          return 'No recordings found for this event.'
        case 'process-data':
          return 'Something went wrong processing your recording data - Please contact a Savvy member for triage.'

        default:
          return null
      }
    },
  },
  watch: {
    pageviewId: {
      handler(v) {
        if (v) this.fetchEvents()
      },
      immediate: true,
    },
    eventsToWatch() {
      this.play()
    },
  },
  beforeDestroy() {
    if (this.player && this.player.destroy) this.player.destroy()
  },
  methods: {
    async fetchEvents() {
      this.loading = true
      this.errortype = null

      try {
        let ref = Firebase.firestore()
          .collection('contacts')
          .doc(this.id)
          .collection('recording-events')

        if (this.pageviewId) ref = ref.where('pageviewId', '==', this.pageviewId)
        else if (this.sessionPageviews) ref = ref.where('pageviewId', 'in', this.sessionPageviews)
        const eventSnap = await ref.orderBy('timestamp').get()

        if (eventSnap.size === 0) {
          this.loading = false
          this.errortype = 'no-recordings'
          return
        }

        const remoteEvents = await Promise.all(
          eventSnap.docs.map(async d => {
            const storageKey = d.get('_firebaseStorageKey')
            if (storageKey) {
              try {
                const url = await Firebase.app()
                  .storage(`gs://savvy-recording-events-live`)
                  .ref(`${storageKey}.json`)
                  .getDownloadURL()
                const { data } = await Axios.get(url)
                return data
              } catch (error) {
                console.error(error)
              }
            }
            return false
          })
        )

        const eventsGrouped = eventSnap.docs.reduce((acc, doc, i) => {
          const docData = remoteEvents[i] || doc.data()

          const events = getEventsFromDocData(docData)
          const bodyStringId = docData.bodyStringId

          if (bodyStringId) {
            const match = acc.find(d => d.bodyStringId === bodyStringId)
            if (match) {
              match.events[docData.bodyStringIndex] = docData.bodyString
            } else {
              const data = { ...docData }
              data.events = []
              data.events[docData.bodyStringIndex] = docData.bodyString
              delete data.bodyString
              delete data.bodyStringIndex
              acc.push(data)
            }
          }

          if (!events) return acc

          return acc.concat(
            events.map(e => ({
              pageviewId: docData.pageviewId,
              ...e,
            }))
          )
        }, [])
        const eventsAssembled = eventsGrouped
          .map(d => {
            if (d.bodyStringId) {
              const allEventsMatch = d.events.filter(e => e).length === d.events.length
              if (!allEventsMatch) this.errortype = 'incomplete-data'
            }
            return d.bodyStringId
              ? { pageviewId: d.pageviewId, ...JSON.parse(d.events.join('')) }
              : d
          })
          .sort((a, b) => a.timestamp - b.timestamp)
        this.events = eventsAssembled
      } catch (error) {
        console.error('Error loading recorded-events', error)
        /* If something goes wrong handle error in here, transition to error loading state */
        if (!this.errortype) this.errortype = 'process-data'
      }

      this.loading = false
    },
    play() {
      if (this.player && this.player.destroy) this.player.destroy()
      this.$refs.videoPlaceholder.innerHTML = ''
      this.$refs.videoContainer.innerHTML = ''

      if (this.eventsToWatch.length === 0) {
        this.noEventsFound = true
        return
      } else {
        this.loading = false
        this.noEventsFound = false
      }

      const width = this.$refs.videoPlaceholder.offsetWidth
      // const height = this.$refs.videoPlaceholder.offsetWidth

      this.player = new rrwebPlayer({
        target: this.$refs.videoContainer, // customizable root element
        props: { events: this.eventsToWatch, autoPlay: true, width, height: 400 },
      })
    },
  },
}

function getEventsFromDocData(data) {
  if (data.bodyStrings) return data.bodyStrings.map(JSON.parse)
  else if (data.bodies) return JSON.parse(data.bodies.join('')).events
  else if (data.body) return JSON.parse(data.body).events
  else return null
}
</script>

<style lang="scss">
@import '@/styles/_variables.scss';
/* @import 'https://cdn.jsdelivr.net/npm/rrweb@latest/dist/rrweb.min.css'; */

.ContactReplay {
  position: relative;
  flex: 1;

  .no-recording-data,
  .error,
  .loading {
    @include flex('center');
    height: 400px;
  }
  .video {
    // position: absolute;
    // width: 500px;
    // --ratio: 0.7;
    // -webkit-transform: scale(var(--ratio));
    // -moz-transform: scale(var(--ratio));
    // -ms-transform: scale(var(--ratio));
    // transform: scale(var(--ratio));

    > .rr-player {
      background: var(--background-a);
      color: white;
      border: 1px solid var(--background-b);
      border-radius: 8px;

      > .rr-controller {
        background: var(--background-b);

        button {
          color: white;
        }
        .rr-timeline__time {
          color: white;
        }
        .rr-progress {
          // background: none;
          border-color: var(--background-b);
        }
      }
    }
  }
}
</style>
