<template>
  <div class="ContactTimeline p-1.5 flex">
    <Entities
      renderless
      :collection="`contacts/${id}/events`"
      v-model="liveEvents"
      :sortBy="eventsSortBy"
      :limit="100"
      @loaded="loaded = true"
    />
    <!-- <a-space direction="vertical" size="large" class="w-full"> -->
    <div class="flex flex-col w-full">
      <ContactSession
        v-for="(session, i) in sessions"
        :key="session.slice(-2)[0] && session.slice(-2)[0].id"
        v-bind="{ contact, session, playbackId, eventsLoaded: loaded }"
        :index="i"
        :canLoadMore="hasMore && sessions.length - 1 === i"
        :immediatePlayback="i === 0 && loaded"
        @play-recording="$emit('play-recording', $event)"
        @load-more="loadMoreEvents"
      />
    </div>
    <!-- </a-space> -->
  </div>
</template>

<script>
import Firebase from 'firebase/app'
import 'firebase/firestore'
import Filters from '@/setup/filters'
import api from '@/helpers/api'
import ContactSession from './ContactSession'
import { mapGetters } from 'vuex'

const getSimpleDate = Filters.find(f => f.name === 'timeSimpleDate').function

export default {
  name: 'ContactTimeline',
  components: {
    ContactSession,
  },
  props: {
    id: String,
    contact: Object,
    playbackId: String,
    usedFallback: Boolean,
  },
  data() {
    return {
      liveEvents: [],
      events: [],
      playbackData: null,
      showAllProps: false,
      loaded: false,
      lastReturnedMaxNum: true,
      lastEventCursor: null,
    }
  },
  computed: {
    ...mapGetters(['activeGroupId']),
    hasMore() {
      return this.lastReturnedMaxNum && this.events.length >= 20
    },
    eventsSortBy() {
      return ['timestamp', 'desc']
    },
    eventsByStep() {
      const events = this.events
        .filter(event => !event.dataSource)
        .map(event => {
          if (event.eventId === 'visit-site') event.eventId = '$pageview'
          if (event.pageviewId === undefined) event.pageviewId = `${Math.random() * 100000}`
          return event
        })
        .reduce((acc, event) => {
          if (
            acc.find(
              e =>
                e.eventId === '$pageview' &&
                event.eventId === '$pageview' &&
                getSimpleDate(e.timestamp) === getSimpleDate(event.timestamp) &&
                e.site &&
                event.site &&
                e.site.url === event.site.url
            )
          ) {
            return acc
          } else {
            return acc.concat([event])
          }
        }, [])
      // .reduce((acc, event) => {
      //   let index = acc.findIndex(p => p.id === event.pageviewId)

      //   if (index === -1) {
      //     index = acc.length
      //     /* Experimental Fix
      //      If Index is not found, need to do some sort of event copying data
      //       to get the timestamps in so that it stops breaking */
      //     acc[index] = { ...event }
      //   }

      //   acc[index] = {
      //     ...acc[index],
      //     id: event.pageviewId,
      //   }

      //   if (event.eventId === '$pageview') {
      //     acc[index] = {
      //       ...event,
      //       ...acc[index],
      //     }
      //   } else {
      //     acc[index].events = [...(acc[index].events || []), event]
      //   }

      //   return acc
      // }, [])
      return events
    },
    sessions() {
      return this.eventsByStep
        .reduce(
          (acc, event, i, arr) => {
            const type = event.site && event.site.query && event.site.query.utm_source
            const frontWrapperTag = { id: 'end', type }
            const backWrapperTag = { id: 'begin', type }

            if (i === 0) {
              if (Date.now() / 1000 - event.timestamp.seconds > 30 * 60)
                acc[0].push(frontWrapperTag)
              acc[0].push(event)
              if (arr.length === 1) acc[0].push(backWrapperTag)
              return acc
            }

            const lastSession = acc[acc.length - 1]
            const lastEvent = lastSession[lastSession.length - 1]
            const lastTimestamp = Array.isArray(lastEvent.events)
              ? lastEvent.events[lastEvent.events.length - 1].timestamp
              : lastEvent.timestamp

            const isNewSession = lastTimestamp.seconds - event.timestamp.seconds > 30 * 60

            if (isNewSession) {
              acc[acc.length - 1].push(backWrapperTag)
              acc.push([frontWrapperTag, event])
            } else lastSession.push(event)

            if (i === arr.length - 1) {
              acc[acc.length - 1].push(backWrapperTag)
            }

            return acc
          },
          [[]]
        )
        .map(s => [...s].reverse())
    },
  },
  watch: {
    usedFallback: {
      handler(v) {
        if (v) {
          this.loadFromFallback()
        }
      },
    },
    liveEvents: {
      handler(events) {
        const newEventsArray = [...events]
        const eventsIds = new Set(events.map(e => e.id))
        this.events.forEach(e => {
          if (!eventsIds.has(e.id)) newEventsArray.push(e)
        })
        if (newEventsArray.length > 0)
          this.lastEventCursor = newEventsArray[newEventsArray.length - 1].timestamp
        this.events = newEventsArray
      },
    },
  },
  methods: {
    async loadFromFallback() {
      const { data } = await api(`/contacts/${this.id}/fetch`, {
        groupId: this.activeGroupId,
        events: true,
      })
      if (data && data.contact) {
        const events = data.contact.map(c => {
          const { _id, ...rest } = c
          return { ...rest, id: _id }
        })
        this.setEvents(events)
        this.usedFallback = true
      }
    },
    async loadMoreEvents() {
      this.loading = true
      // let events = []
      // while (events.length < 20 && this.lastReturnedMaxNum) {
      try {
        const moreEvents = await this.getEvents()
        // events = [...events, ...moreEvents]
        // }
        this.setEvents(moreEvents)
      } catch (error) {
        console.error(error)
      }
      this.loading = false
    },
    async getEvents(limit) {
      const lastEventCursor = this.lastEventCursor // this.events[this.events.length - 1].timestamp
      const events = await Firebase.firestore()
        .collection('contacts')
        .doc(this.id)
        .collection('events')
        .orderBy(...this.eventsSortBy)
        .startAfter(lastEventCursor)
        // .limit(limit)
        .get()

      if (events.size !== limit) this.lastReturnedMaxNum = false
      this.lastEventCursor = events.docs[events.docs.length - 1].get('timestamp')

      return events //.docs.filter(e => !e.get('dataSource'))
    },
    setEvents(events) {
      const newEventsArray = [...this.events]
      const eventsIds = new Set(this.events.map(e => e.id))
      events.forEach(e => {
        if (!eventsIds.has(e.id)) newEventsArray.push(typeof e.data === 'function' ? e.data() : e)
      })

      this.events = newEventsArray //.filter(e => !e.dataSource)
    },
  },
}
</script>
