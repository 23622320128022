<template>
  <a-dropdown>
    <slot />
    <!-- <SafeImage
      class="profile-picture"
      :src="user ? user.picture : ''"
      fallback="/images/profile.png"
    /> -->
    <a-menu slot="overlay">
      <a-menu-item v-if="projects.length > 1">
        <router-link to="/projects">
          <a-icon type="block" />
          Projects
        </router-link>
      </a-menu-item>
      <a-menu-item
        v-if="isAdmin || ['fxBRfFf86NatwbLBLgJ2', 'qdrHzNJhXcg3ourgTjw3'].includes(activeGroupId)"
      >
        <router-link to="/all-flows">
          <a-icon type="block" />
          All Flows
        </router-link>
      </a-menu-item>
      <a-divider />
      <a-menu-item>
        <router-link to="/settings">
          <a-icon type="setting" />
          Settings
        </router-link>
      </a-menu-item>
      <a-menu-item>
        <router-link to="/settings/billing">
          <a-icon type="credit-card" />
          Billing
        </router-link>
      </a-menu-item>
      <a-divider />
      <a-menu-item @click="signOut">
        <div>
          <a-icon type="logout" />
          Sign Out
        </div>
      </a-menu-item>
    </a-menu>
  </a-dropdown>
</template>
<script>
import { mapGetters, mapState } from 'vuex'
import { logout } from '@/boot-up/authentication'

export default {
  props: {
    projects: Array,
  },
  data() {
    return {}
  },
  computed: {
    ...mapState(['groups']),
    ...mapGetters(['isAdmin', 'activeGroupId']),
    user() {
      return this.$store.getters.user
    },
  },
  watch: {},
  methods: {
    async signOut() {
      await logout()
      this.$router.push('/login')
    },
  },
}
</script>
