<template>
  <div class="EntryCard p-4 mb-4 flex flex-col bg-white rounded-lg">
    <div class="entry-header flex justify-between items-center pb-7">
      <div class="text-base">{{ card.title || title }}</div>
      <!-- <div v-if="card.title" class="flow-title font-medium">{{ card.title }}</div> -->
    </div>
    <div class="entry-content divide-y scroller-x">
      <!-- <div class="flex items-center justify-between py-1 text-lg font-bold">
        <div class="w-4/12">Key</div>
        <div class="w-7/12">Value</div>
      </div> -->
      <div
        class="flex items-center justify-between py-1"
        v-for="answer in card.answers"
        :key="answer.label"
      >
        <div class="w-4/12 uppercase text-xs font-semibold tracking-wide text-gray-500">
          {{ answer.label }}
        </div>
        <div class="w-7/12 font-medium">{{ answer.value || '-' }}</div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: { title: String, card: Object },
}
</script>
<style lang="scss" scoped>
@import '@/styles/_variables.scss';
.EntryCard > div > .flow-title {
  color: $savvy;
}
</style>
