<template>
  <div class="ContactSession">
    <a-timeline reverse>
      <a-timeline-item
        v-for="(pageview, index) in session"
        :key="pageview.id"
        :color="['begin', 'end'].includes(pageview.id) ? '#bbb' : '#1890ff'"
      >
        <ContactStep
          v-if="canLoadMore ? index !== session.length - 1 : true"
          :id="pageview.id"
          :isBeingViewed="playbackId === pageview.pageviewId"
          v-bind="{
            pageview,
            extraFromSession: extraEventData[index],
          }"
          @play-recording="playRecording"
        />
        <Button
          v-else
          class="borderless load-more"
          icon="caret-down"
          text="Load All Events"
          @click.native="$emit('load-more')"
        />
      </a-timeline-item>
    </a-timeline>
  </div>
</template>

<script>
import get from 'lodash/get'
import cloneDeep from 'lodash/cloneDeep'
import Firebase from 'firebase/app'
import 'firebase/functions'
import getCountryISO3 from 'country-iso-2-to-3'

import ContactStep from './ContactStep'

export default {
  components: { ContactStep },
  props: {
    contact: Object,
    session: Array,
    playbackId: String,
    immediatePlayback: Boolean,
    index: Number,
    canLoadMore: Boolean,
    eventsLoaded: Boolean,
  },
  data() {
    return {
      source: null,
      extraEventData: [],
    }
  },
  computed: {
    firstEvent() {
      return this.session.find(event => !['begin', 'end'].includes(event.id))
    },
    sessionOngoing() {
      return !this.session.find(event => event.id === 'end')
    },
    pageviewsInSession() {
      return this.session.reduce((acc, s) => {
        if (s.pageviewId) acc.push(s.pageviewId)
        return acc
      }, [])
    },
    detectedSource() {
      if (!this.firstEvent) return undefined

      return (
        (this.firstEvent.site &&
          this.firstEvent.site.query &&
          this.firstEvent.site.query.utm_source) ||
        null
      )
    },
  },
  watch: {
    eventsLoaded: {
      handler(v) {
        if (this.immediatePlayback && v) {
          const firstEventId = this.firstEvent && this.firstEvent.pageviewId
          if (firstEventId) this.playRecording(firstEventId)
        }
      },
      immediate: true,
    },
    detectedSource: {
      async handler(source) {
        /* Placeholder until Google Search platform stuff is done */

        if (source) {
          this.source = source
        } else if (source === null) {
          const searchTerms = await this.getSearchTermEstimation()

          if (searchTerms && searchTerms.length) {
            this.source = 'search'
            this.sourceData = {
              searchTerms,
            }
            this.extraEventData[0] = {
              firstStepLabel: `Began Session (Likely searched: ${searchTerms
                .map(term => `'${term.searchTerm}'`)
                .join(' or ')})`,
              source: 'search',
              searchTerms,
            }
            this.extraEventData = cloneDeep(this.extraEventData)
          }
        }

        /* Do Google Search Platform stuff - only if detectedSource is null. if undefined, ignore (means things aren't loaded yet) */
        /* this.source gets emitted up by this.playRecording and is used to determine the left side preview inside of ContactRow */
      },
      immediate: true,
    },
  },
  methods: {
    playRecording(id) {
      this.$emit('play-recording', {
        playbackId: id,
        sessionPageviews: this.pageviewsInSession,
        detectedSource: this.source,
        sourceData: this.sourceData,
      })
    },
    async getSearchTermEstimation() {
      const COUNTRY = 0
      // const DEVICE = 1
      const PAGE = 1
      const QUERY = 2
      const startingEvent = this.session.find(s => s.eventId === '$pageview')

      const rows = await this.getSearchConsoleResults(startingEvent)

      if (!rows) return null

      const countryCode = getCountryISO3(this.contact.country_code).toLowerCase()
      // const deviceCode = ''
      if (!startingEvent) return
      const startingUrl = startingEvent.url

      const eligibleRows = rows.filter(
        row =>
          row.clicks > 0 &&
          row.keys[COUNTRY] === countryCode &&
          // row.keys[DEVICE] === deviceCode &&
          urlsAreEqual(row.keys[PAGE], startingUrl)
      )

      return eligibleRows.map(row => ({
        ...row,
        searchTerm: row.keys[QUERY],
        weight: row.clicks,
      }))
    },
    async getSearchConsoleResults(startingEvent) {
      if (!startingEvent) return

      const date = firebaseTimestampToDateString(startingEvent.timestamp)
      const group = this.$store.getters.group(this.$store.getters.activeGroupId)
      const siteUrl = get(group, `accounts.google_search_console`)

      if (!siteUrl) return

      const variables = {
        siteUrl,
        startDate: date,
        endDate: date,
      }

      const runStoredQuery = Firebase.functions().httpsCallable('runStoredQuery')
      const { data } = await runStoredQuery({ queryId: 'search-console', variables })

      return get(data, `data.google.searchConsole.site.query.rows`)
    },
  },
}

function urlsAreEqual(url1, url2) {
  if (url1.endsWith('/')) url1 = url1.slice(0, -1)
  if (url2.endsWith('/')) url2 = url2.slice(0, -1)

  return url1 === url2
}

function firebaseTimestampToDateString(timestamp) {
  const dateObj = new Date(timestamp.seconds * 1000)
  const month = `${dateObj.getMonth() + 1}`
  const date = `${dateObj.getDate()}`
  const YYYY = `${dateObj.getFullYear()}`
  const MM = month.length === 1 ? `0${month}` : month
  const DD = date.length === 1 ? `0${date}` : date

  return `${YYYY}-${MM}-${DD}`
}
</script>

<style lang="scss">
@import '@/styles/_variables.scss';

.ContactSession {
  .ant-timeline-item-head-custom {
    background: none;
  }
}
</style>
