<template>
  <div v-if="!hidden" class="HomeGroupSelection">
    <a-dropdown v-if="$store.getters.activeGroup">
      <a class="ant-dropdown-link" @click="$router.push('/projects')">
        {{ $store.getters.activeGroup.name }}
      </a>
      <a-menu slot="overlay">
        <a-menu-item v-for="group in availGroups" :key="group.id" @click="updateActiveGroup(group)">
          <a-icon type="user" /> {{ group.text }}
        </a-menu-item>
      </a-menu>
    </a-dropdown>
    <!-- <Dropdown
      :style="{ pointerEvents: availGroups.length > 1 ? 'all' : 'none' }"
      :buttons="availGroups"
      :class="{ 'bottom-aligned': bottomAligned }"
      isRadio
      scrollable
      v-model="selectedGroup"
    >
      <slot />
    </Dropdown> -->
  </div>
</template>
<script>
import Firebase from 'firebase/app'
import 'firebase/firestore'
import { mapState, mapGetters } from 'vuex'
import { getDefaultNewFlow } from '@/components/form/editor/helpers/formHelpers'
export default {
  props: {
    hidden: Boolean,
    groupId: String,
    hasHomeChannel: Boolean,
    showButton: { type: Boolean, default: true },
    bottomAligned: { type: Boolean, default: false },
  },
  computed: {
    ...mapState(['groups']),
    ...mapGetters(['isAdmin', 'user', 'activeGroupId']),
    recentActiveGroupIds() {
      return (this.user && this.user.recentActiveGroupIds) || []
    },
    groupsByUse() {
      const recentGroupsSet = new Set(this.recentActiveGroupIds)
      const recentGroups = this.groups
        .filter(g => recentGroupsSet.has(g.id))
        .reduce((acc, g) => ({ ...acc, [g.id]: g }), {})

      const recentOrderedGroups = this.recentActiveGroupIds
        .map(id => recentGroups[id])
        .filter(g => g)
      const otherGroups = this.groups.filter(g => !recentGroupsSet.has(g.id))

      return [...recentOrderedGroups, ...otherGroups]
    },
    availGroups() {
      return this.groupsByUse
        .filter(
          group =>
            this.isAdmin ||
            (group &&
              this.user &&
              this.user.uid &&
              (group.users || []).includes(this.user && this.user.uid))
        )
        .map(g => ({
          value: g.id,
          text: g.name,
          itemId:
            (g.title &&
              g.title
                .toLowerCase()
                .split(' ')
                .join('-')) ||
            g.id,
        }))
        .filter(g => g.text)
    },
  },
  mounted() {
    const self = this
    if (window.CommandBar) {
      window.CommandBar.addCallback('changeGroup', args => {
        const groupId = args.group.id
        self.updateActiveGroup({ value: groupId })
      })
      window.CommandBar.addCallback('createNewFlow', args => {
        this.createNewFlow(args.title, args.group && args.group.id)
      })
      window.CommandBar.addCallback('viewFlow', args => {
        this.viewFlow(args.flow)
      })
    }
  },
  watch: {
    availGroups: {
      handler(availGroups) {
        this.$emit('input', availGroups)

        if (window.CommandBar) {
          window.CommandBar.addContext({
            groups: availGroups.map(g => ({ ...g, id: g.value, label: g.text })),
          })
        }
      },
      immediate: true,
    },
    groupId: {
      handler(id) {
        this.selectedGroup = id
      },
      immediate: true,
    },
  },
  methods: {
    async viewFlow(flow) {
      const flowId = flow.id
      const flowGroupId = flow.groupId
      if (
        this.activeGroupId !== flowGroupId &&
        this.availGroups.find(g => g.value === flowGroupId)
      ) {
        await this.updateActiveGroup({ value: flowGroupId })
      }
      const url = `/project/flows/${flowId}/edit`
      this.$router.push(url)
    },
    async createNewFlow(title, groupId) {
      const batch = Firebase.firestore().batch()
      const flowRef = Firebase.firestore()
        .collection('forms')
        .doc()
      const id = flowRef.id
      const group = groupId || this.activeGroupId
      const { form, flow } = getDefaultNewFlow(id, title, group)

      batch.set(flowRef, flow)
      const historyRef = flowRef.collection('history').doc(`version-0`)
      const userName =
        this.$store.getters.user.nickname ||
        (this.$store.getters.user.auth &&
          (this.$store.getters.user.auth.displayName || this.$store.getters.user.auth.email))
      const author = { userId: this.$store.getters.userId, userName: userName || 'Unnamed User' }
      batch.set(historyRef, {
        groupId: group,
        form: JSON.stringify(form),
        author,
        saved: true,
        status: 'prod',
        version: 0,
        from_version: null,
        updatedAt: Firebase.firestore.FieldValue.serverTimestamp(),
      })

      await batch.commit()
      await this.$nextTick()
      let url = `/project/flows/${flowRef.id}/edit`
      if (groupId && groupId !== this.activeGroupId) {
        await this.updateActiveGroup({ value: groupId })
      }
      this.$router.push(url)
    },
    async updateActiveGroup(group) {
      Firebase.firestore()
        .collection('users')
        .doc(this.$store.getters.userId)
        .update({ activeGroupId: group.value })
      this.$router.replace('/')
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@/styles/_variables.scss';

.HomeGroupSelection {
  @include flex('row, ' 'a-center');
  white-space: nowrap;
  margin: 0 0.5em;
  padding: 0.2em 0.5em 0.2em;
  border-radius: 6px;
  cursor: pointer;

  &:hover {
    background: rgba(89, 105, 129, 0.1);
    color: var(--color-savvy);
  }
}
</style>
