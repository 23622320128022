<template>
  <div class="ContactStep flex w-full" v-if="pageview" :class="[id, { isBeingViewed, isBeginEnd }]">
    <template v-if="isBeginEnd">
      <div class="w-4/12">
        <span class="text-xs">
          {{ label }}
        </span>
      </div>
    </template>
    <div class="flex flex-col w-full" v-else-if="isExpandedEvent">
      <div class="flex flex-row items-end text-lg font-medium -mt-1">
        <!-- <a-tag> -->
        {{ label }}
        <!-- </a-tag> -->
        <div
          @click="selectRecording"
          class="cursor-pointer w-1/12 border rounded flex justify-center items-center text-sm font-base ml-2 mb-0.5"
        >
          <a-icon type="caret-right" /> Play
        </div>
      </div>
      <div v-if="pageview.timestamp" class="text-sm font-base cursor-default">
        <a-tooltip>
          <template slot="title"> At {{ pageview.timestamp | timeSimpleDate }} </template>
          Visited {{ pageview.timestamp | fromNow }}
        </a-tooltip>
      </div>
      <div class="w-full flex flex-row">
        <a-tooltip :title="pageview.url">
          <a>{{ cleanUrl }}</a>
        </a-tooltip>
      </div>
    </div>
    <template v-else>
      <div class="w-9/12">
        <a-tooltip :title="cleanUrl">
          <a-tag>{{ label }}</a-tag>
        </a-tooltip>
        <template v-if="eventContext !== undefined">{{ eventContext }} </template>
        <template v-if="currentFlow">{{ currentFlow.title || 'Untitled Flow' }}</template>
      </div>
      <div v-if="pageview.timestamp" class="cursor-default w-3/12 text-right">
        <a-tooltip>
          <template slot="title"> At {{ pageview.timestamp | timeSimpleDate }} </template>
          {{ pageview.timestamp | timeSimpleDate }}
        </a-tooltip>
      </div>
    </template>
  </div>
</template>

<script>
import get from 'lodash/get'

import { copyToClipboard } from '@/helpers/clipboard'
import { mapGetters } from 'vuex'

export default {
  name: 'ContactStep',
  props: {
    id: String,
    pageview: Object,
    isBeingViewed: Boolean,
    extraFromSession: Object,
  },
  computed: {
    ...mapGetters(['flows']),
    label() {
      switch (this.pageview.eventId) {
        case '$pageview':
        case undefined:
          return this.buttonText
        case 'checkpoint_triggered':
          return `Checkpoint: ${(this.pageview.data.checkpoint || ['Unknown'])
            .join(', ')
            .split(/-|_/)
            .join(' ')}`
        case 'reached_flow_page': {
          const pageKey = (
            (this.flows && this.flows[0].pages.find(p => p.id === this.pageview.data.pageId)) ||
            {}
          ).key
          return `Reached Flow Page: ${(pageKey || this.pageview.data.pageId)
            .split(/-|_/)
            .join(' ')}`
        }

        default:
          return this.pageview.eventId.split(/-|_/).join(' ')
      }
    },
    currentFlow() {
      const flowId = this.pageview && this.pageview.data && this.pageview.data.flowId
      if (!flowId) return null
      return this.flows.find(f => f.id === flowId) || {}
    },
    eventContext() {
      switch (this.pageview.eventId) {
        case 'reached_flow_page':
          return `${this.pageview.data.pageIndex + 1} of`

        default:
          return undefined
      }
    },
    isBeginEnd() {
      return ['begin', 'end'].includes(this.id)
    },
    isExpandedEvent() {
      return this.pageview.eventId === '$pageview'
    },
    cleanUrl() {
      return (this.pageview.site && this.pageview.site.clean) || this.pageview.url
    },
    buttonIcon() {
      if (this.pageview.id === 'begin' || this.pageview.id === 'end') {
        return 'sign-in-alt'
      }
      return 'play-circle'
    },
    buttonText() {
      if (this.pageview.id === 'begin' || this.pageview.id === 'end') {
        return this.pageview.id === 'begin'
          ? (this.extraFromSession && this.extraFromSession.firstStepLabel) || 'Began Session'
          : 'Session Ended'
      }

      const source =
        get(this.pageview, `site.query.utm_source`) ||
        get(this.referrer, 'domain') ||
        get(this.referrer, 'host')

      const sourceExists = source && source !== get(this.pageview, `site.domain`)

      return 'Page View' + (sourceExists ? ` (via ${source})` : ``)
    },
    referrer() {
      return get(this.pageview, `referrer.domain`) || get(this.pageview, `referrer.host`)
        ? this.pageview.referrer
        : null
    },
  },
  methods: {
    selectRecording() {
      this.$emit('play-recording', this.pageview.pageviewId)
    },
    copy(text) {
      copyToClipboard(text)
      this.$message.info(`URL copied to clipboard`)
    },
  },
}
</script>

<style lang="scss">
.ContactStep {
  .ant-tag {
    text-transform: capitalize;
  }
}
</style>
